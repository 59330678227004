import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import ClientsBanner from '../components/ClientsBanner';

export const BlogPostTemplate = (props) => {
  const {
    title,
    venueName,
    blogText,
    metaTitle,
    metaDescription,
    clientName,
    clientReview,
    photos,
  } = props.data.blogPosts;

  return (
    <Layout>
      <Helmet>
        <title>{metaTitle} - Audio Vultures Wedding Band</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>{title}</h1>
            </header>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: blogText.childMarkdownRemark.html,
              }}
            />
            <hr className="major " />

            <h3>Photos from our evening at {venueName}</h3>
            <div className="row">
              {photos.map((photo) => {
                return (
                  <div className="4u 12u$(small)" key={photo.file.url}>
                    <img
                      src={`https://${photo.file.url}`}
                      style={{ width: '100%' }}
                      alt={`Audio Vultures Wedding Band live at ${venueName}`}
                    />
                    {photo.description && <h6>© {photo.description}</h6>}
                  </div>
                );
              })}
            </div>

            {clientReview?.clientReview && (
              <React.Fragment>
                <hr className="major major-hr" />
                <div className="blog-quote">
                  <p>
                    <span className="icon fa-quote-left" />
                    <span className="review-text">{clientReview.clientReview}</span>{' '}
                    <span className="icon fa-quote-right" />
                  </p>
                  <h4>
                    <em>{clientName}</em>
                  </h4>
                </div>
                <hr className="major major-hr" />
              </React.Fragment>
            )}
            <ClientsBanner />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    blogPosts: contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      venueName
      clientName
      metaTitle
      metaDescription
      blogText {
        childMarkdownRemark {
          html
        }
      }
      photos {
        title
        file {
          url
        }
        description
      }
      clientReview {
        clientReview
      }
    }
  }
`;
